// 20211115RBP - Added custom dynamic form extensions for our Date of Birth property
// based on: https://docs.abp.io/en/abp/latest/UI/Angular/Dynamic-Form-Extensions#step-2-import-and-use-form-prop-contributors
// src/app/form-prop-contributors.ts

import {
  eIdentityComponents,
  IdentityCreateFormPropContributors,
  IdentityEntityActionContributors,
  IdentityEntityPropContributors,
  IdentityUserDto,
} from '@volo/abp.ng.identity';
import { EntityAction, EntityActionList, EntityProp, EntityPropList, ePropType, FormProp, FormPropList, FormPropOptions } from '@abp/ng.theme.shared/extensions';
import { Validators } from '@angular/forms';
import { ConfigStateService } from '@abp/ng.core';
import { CoveredEntityCrudService } from '@proxy/covered-entities';
import { finalize, map, } from 'rxjs/operators';
import { of } from 'rxjs';
import { AccountService } from '@volo/abp.ng.account/public';

const allZeroUUID = '00000000-0000-0000-0000-000000000000';

const roleNameProp = new EntityProp<IdentityUserDto>({
  type: ePropType.String,
  name: 'roleName',
  displayName: 'Role',
  sortable: true,
  valueResolver: (data) => { return of(data.record.extraProperties["RoleName"]) }
});

const coveredEntityProp = new EntityProp<IdentityUserDto>({
  type: ePropType.String,
  name: 'coveredEntityName',
  displayName: 'Covered Entity Name',
  sortable: true,
  visible: data => data.record.extraProperties["IsCoveredEntityAdmin"] === true,
  valueResolver: (data) => { return of(data.record.extraProperties["CoveredEntityName"]) }
});

const firstNameProp = new EntityProp<IdentityUserDto>({
  type: ePropType.String,
  name: 'name',
  displayName: 'First Name',
  sortable: true,
  valueResolver: (data) => { return of(data.record.name) }
});

const lastNameProp = new EntityProp<IdentityUserDto>({
  type: ePropType.String,
  name: 'surname',
  displayName: 'Last Name',
  sortable: true,  
  valueResolver: (data) => { return of(data.record.surname) }
});

export function coveredEntityDropDownPropContributor(propList: FormPropList<IdentityUserDto>) {
  const currentProp = propList.find(x => x.value.name === 'TenantId');
  const coveredEntityDto = propList.find(x => x.value.name === 'CoveredEntity');
  const previousValue = currentProp.value;

  const options: FormPropOptions<IdentityUserDto> = {
    ...currentProp,
    type: ePropType.Enum,
    name: 'TenantId',
    displayName: 'Covered Entity',
    validators: () => [Validators.required],
    isExtra: true,
    //Verify if it is possible to get user tenantId to put here as default value so we can identify when updating
    //if current tenant is null then it means it is the main tenant
    //Check if Identity entity has the tenantId, if it does then check how to pre-populate dropdown using default value  

    options: data => {
      const coveredEntityService = data.getInjected(CoveredEntityCrudService);
      const dataResult = coveredEntityService.getAll()
        .pipe(
          map((result) => {
            if (result) {
              const results = result.items.map(item => ({ key: item.name, value: item.tenantId }));

              //Sets admin at the top of the list
              results.unshift({ key: 'Sisu', value: allZeroUUID });

              return results;
            }
            return null;
          })
        );

      return dataResult;
    }
  };
  const prop = new FormProp(options);

  propList.addAfter(prop, previousValue);
  propList.dropByValue(previousValue);
  propList.dropByValue(coveredEntityDto.value);
}

//Credit: https://support.abp.io/qa/questions/2691/16ded54c-15ca-8d98-7aa7-3a027dd4002c
export function emailAddressFormPropContributor(propList: FormPropList<IdentityUserDto>) {
  const prop = propList.find(x => x.value.name === 'userName');
  let previousEmailProp = propList.get(propList.findIndex(p => p.value.name == 'userName')).value
  propList.dropByValue('userName', (prop, text) => prop.name === text)
  propList.addHead(
    {
      ...previousEmailProp,
      visible: c => false,
      //Adds default email on form because it is required by ABP.
      //Will not be used but needs to be here
      defaultValue: 'default@email.com',
      validators: (x: { getInjected: (arg0: typeof ConfigStateService) => any; }) => {
        //Remove required validation
        return null;
      },
    } as FormProp<IdentityUserDto>
  );

  let emailPropIndex = propList.findIndex(p => p.value.name == 'email');
  propList.addHead(propList.get(emailPropIndex).value);
  propList.dropByIndex(emailPropIndex + 1);
}

export function lockoutEnabledFormPropContributor(propList: FormPropList<IdentityUserDto>) {
  const prop = propList.find(x => x.value.name === 'lockoutEnabled');
  propList.dropByValue(prop.value);
}

export function passwordFormPropContributor(propList: FormPropList<IdentityUserDto>) {
  //SHEL-155 - Remove password form field from front-end
  const prop = propList.find(x => x.value.name === 'password');
  if (prop) {
    const previousValue = prop.value;
    const newProp = new FormProp<IdentityUserDto>(
      Object.assign({},
        previousValue,
        {
          visible: () => false,
          //Since password is required by the backend validation, send a default value
          defaultValue: 'Password123!',
          type: ePropType.String,
          validators: (x: { getInjected: (arg0: typeof ConfigStateService) => any; }) => {
            return [Validators.required];
          }
        }));

    propList.addAfter(newProp, previousValue);
    propList.dropByValue(previousValue);
  }
}

export function nameFormPropContributor(propList: FormPropList<IdentityUserDto>) {
  const nameProp = propList.find(x => x.value.name === 'name');

  if (nameProp) {
    const newPropOptions: FormPropOptions<IdentityUserDto> = {
      ...nameProp.value,
      displayName: 'First Name',
      validators: () => [Validators.required]
    };

    const newProp = new FormProp(newPropOptions);

    propList.addAfter(newProp, nameProp.value);
    propList.dropByValue(nameProp.value)
  }

  const surNameProp = propList.find(x => x.value.name === 'surname');

  if (surNameProp) {
    const newPropOptions: FormPropOptions<IdentityUserDto> = {
      ...surNameProp.value,
      displayName: 'Last Name',
      validators: () => [Validators.required]
    };

    const newProp = new FormProp(newPropOptions);

    propList.addAfter(newProp, surNameProp.value);
    propList.dropByValue(surNameProp.value)
  }
}

export function tenantIdPropContributor(propList: EntityPropList<IdentityUserDto>) {
  const currentProp = propList.find(x => x.value.name === 'TenantId');
  propList.dropByValue(currentProp.value);
}
export function userNamePropContributor(propList: EntityPropList<IdentityUserDto>) {
  const currentProp = propList.find(x => x.value.name === 'userName');
  propList.dropByValue(currentProp.value);
}

export function namePropContributor(propList: EntityPropList<IdentityUserDto>) {
  propList.addAfter(firstNameProp, 'email', (value, name) => value.name === name);

  propList.addAfter(lastNameProp, firstNameProp.name, (value, name) => value.name === name);
}

export function coveredEntityPropContributor(propList: EntityPropList<IdentityUserDto>) {
  let currentCoveredEntityPropIndex = propList.findIndex(p => p.value.name == 'CoveredEntity');
  propList.dropByIndex(currentCoveredEntityPropIndex);
  propList.addAfter(coveredEntityProp, 'phoneNumber', (value, name) => value.name === name);
}

export function roleNamePropContributor(propList: EntityPropList<IdentityUserDto>) {
  let currentRoleNamePropIndex = propList.findIndex(p => p.value.name == 'RoleName');
  propList.dropByIndex(currentRoleNamePropIndex);

  propList.addAfter(roleNameProp, coveredEntityProp.name, (value, name) => value.name === name);
}

export function phoneNumberPropContributor(propList: EntityPropList<IdentityUserDto>) {
  let currentRoleNamePropIndex = propList.findIndex(p => p.value.name == 'phoneNumber');
  propList.dropByIndex(currentRoleNamePropIndex);
}

export const identityCreateFormPropContributors: IdentityCreateFormPropContributors = {
  // enum indicates the page to add contributors to
  [eIdentityComponents.Users]: [
    emailAddressFormPropContributor,
    lockoutEnabledFormPropContributor,
    coveredEntityDropDownPropContributor,
    passwordFormPropContributor,
    nameFormPropContributor
  ]
};

export const identityEntityPropContributors: IdentityEntityPropContributors = {
  [eIdentityComponents.Users]: [
    tenantIdPropContributor,
    userNamePropContributor,
    namePropContributor,
    coveredEntityPropContributor,
    roleNamePropContributor,
    phoneNumberPropContributor
  ]
}

export const identityEditFormPropContributors = identityCreateFormPropContributors;
// you may define different contributors for edit form if you like

export function customActionContributor(actionList: EntityActionList<IdentityUserDto>) {
  const actionIndices = [
    actionList.findIndex(p => p.value.text === "AbpIdentity::Claims"),
    actionList.findIndex(p => p.value.text === "AbpIdentity::Lock"),
    actionList.findIndex(p => p.value.text === "AbpIdentity::Unlock"),
    actionList.findIndex(p => p.value.text === "AbpIdentity::Permissions"),
    actionList.findIndex(p => p.value.text === "AbpIdentity::ChangeHistory"),
    actionList.findIndex(p => p.value.text === "AbpIdentity::TwoFactor"),
    actionList.findIndex(p => p.value.text === "AbpIdentity::Delete")
  ];

  actionIndices.sort((a, b) => b - a);

  // Drop actions by index if they are valid
  actionIndices.forEach(index => {
    if (index !== -1) {
      actionList.dropByIndex(index);
    }
  });
}

const sendResetPasswordAction = new EntityAction<IdentityUserDto>({
  //localize text?
  text: 'Send Password Reset Email',
  action: data => {
    const accountService = data.getInjected(AccountService);

    accountService
      .sendCustomPasswordReset(
        {
          email: data.record.email,
          returnUrl: "simplinity://reset-password",
          appName: 'Angular'
        }
      )
      .subscribe(() => {
        console.log('subscribe triggered');
      });
  },
});

export function sendResetPasswordActionContributor(actionList: EntityActionList<IdentityUserDto>) {
  const setPasswordActionIndex = actionList.findIndex(a => a.value.text === "AbpIdentity::SetPassword")
  actionList.dropByIndex(setPasswordActionIndex);
  actionList.addTail(sendResetPasswordAction)
}
export const identityEntityActionContributors: IdentityEntityActionContributors = {
  // enum indicates the page to add contributors to
  [eIdentityComponents.Users]: [
    customActionContributor,
    sendResetPasswordActionContributor,
  ],
};